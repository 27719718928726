
import { computed, defineComponent, ref } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'CampoNumerico',
  components: {
  },
  props: {
    valor: {
      type: Number,
      default: 0,
    },
    quantidadeCasasDecimais: {
      type: Number,
      default: 2,
    },
    monetario: {
      type: Boolean,
      default: false,
    },
    percentual: {
      type: Boolean,
      default: false,
    },
    mascara: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    permitirValorNegativo: {
      type: Boolean,
      default: false,
    },
    classCss: {
      type: String,
      default: '',
    },
    classGroupCss: {
      type: String,
      default: '',
    },
    valorMaximo: {
      type: Number,
      default: 0,
    },
    fracionar: {
      type: Boolean,
      default: true,
    },
    info: {
      type: String,
      default: '',
    },
    emDigitacao: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'update:emDigitacao', 'change', 'focus', 'blur', 'valorDigitado'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();
    const refCampoNumerico = ref<HTMLElement | null>(null);
    const digitouValor = ref<boolean>(false);
    const valorEmDigitacao = ref<string>('');

    const computedEmDigitacao = computed({
      get: () => props.emDigitacao,
      set: (val: boolean) => {
        emit('update:emDigitacao', val);
      },
    });

    const computedValor = computed({
      get: () => props.valor,
      set: (valor: number) => {
        if (props.valorMaximo > 0 && valor > props.valorMaximo && !props.disabled) {
          apresentarMensagemAlerta(`O valor ${valor} é maior que o valor máximo de ${props.valorMaximo}`);
          emit('update:valor', 0);
        } else if (props.fracionar) {
          emit('update:valor', valor);
        } else if (!props.fracionar) {
          if (valor < 1) {
            const novoValor = valor * 10;
            emit('update:valor', novoValor.toFixed(props.quantidadeCasasDecimais));
          } else if (valor > 1 && valor.toString().includes('.')) {
            const valorInteiro = Math.trunc(valor);
            emit('update:valor', valorInteiro.toFixed(props.quantidadeCasasDecimais));
          } else {
            emit('update:valor', valor);
          }
        }
      },
    });

    function change() {
      emit('change', computedValor.value);
    }

    function inputFocus(event: any) {
      emit('focus');
      event.target.select();
    }

    function focus() {
      if (refCampoNumerico.value) {
        refCampoNumerico.value.focus();
      }
    }

    function validarInformacaoCampo(evento:any, informacao: any) {
      if (props.quantidadeCasasDecimais === 0) {
        if (!/[0-9]/.test(informacao)) {
          evento.preventDefault();
          return;
        }
      }

      if (!/^[\d,.?!]+$/.test(informacao)) {
        evento.preventDefault();
      }
    }

    function validarInformacaoDigitada(evento:any) {
      const charCode = String.fromCharCode(evento.keyCode);
      validarInformacaoCampo(evento, charCode);
    }

    function validarInformacaoColada(evento:any) {
      evento.preventDefault();
      const valorColado = evento.clipboardData.getData('text');
      const valorNumerico = parseFloat(valorColado.replace(',', '.'));

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(valorNumerico)) {
        const valorFormatado = valorNumerico.toFixed(props.quantidadeCasasDecimais);
        valorEmDigitacao.value = valorFormatado;
        emit('update:valor', valorFormatado);
      }
    }

    function preparaValorDigitado() {
      let novoValor = 0;

      if (UtilitarioGeral.valorValido(valorEmDigitacao.value)) {
        novoValor = Number(valorEmDigitacao.value.replace('.', '').replace(',', '.'));
        novoValor = Number(UtilitarioMascara.mascararValor(novoValor, props.quantidadeCasasDecimais, false, props.permitirValorNegativo).replace('.', '').replace(',', '.'));
      }
      computedValor.value = novoValor;
    }

    function valorDigitado(event : any) {
      if ((event.keyCode >= 48 && event.keyCode <= 57)
      || (event.keyCode >= 96 && event.keyCode <= 105)
      || event.keyCode === 46 || event.keyCode === 110 || event.keyCode === 190
      || event.keyCode === 8 || event.keyCode === 194 || event.keyCode === 219) {
        // computedEmDigitacao.value = true;
        digitouValor.value = true;
        valorEmDigitacao.value = event.target.value;
        emit('update:valor', Number(event.target.value.replace(',', '.')));
        emit('valorDigitado');
      }
    }

    function blur() {
      computedEmDigitacao.value = false;
      if (digitouValor.value) {
        preparaValorDigitado();
        emit('update:valor', computedValor.value);
        emit('change', computedValor.value);
        digitouValor.value = false;
      }
      valorEmDigitacao.value = '';
      emit('blur', computedValor.value);
    }

    function digitandoValor(event:any) {
      digitouValor.value = true;
      valorEmDigitacao.value = event.target.value;
    }
    function apresentaValorMascarado():any {
      if (digitouValor.value === true) {
        return valorEmDigitacao.value;
      }
      return UtilitarioMascara.mascararValor(computedValor.value, props.quantidadeCasasDecimais, false, props.permitirValorNegativo);
    }
    return {
      props,
      computedValor,
      refCampoNumerico,
      focus,
      inputFocus,
      change,
      blur,
      valorDigitado,
      apresentaValorMascarado,
      digitandoValor,
      valorEmDigitacao,
      validarInformacaoColada,
      validarInformacaoDigitada,
    };
  },
});
